
/* IMPORTAÇÕES */

import TemplateFunctions from './modules/Template.js';
import Formularios from './modules/Formularios.js';
import Home from './modules/Home.js';

/* INSTANCIA CLASSES */

const template = new TemplateFunctions;
const home = new Home;
const formulario = new Formularios;

/* RUN EVENTOS HABILITANDO JQUERY */

$(function()
{
    if( $("body.home").length > 0 )
    {
        home.init();        
    }

    if( $(".formularioStyle.ajaxForm").length > 0 )
    {
        $(".formularioStyle.ajaxForm").each(function()
        {
            formulario.init( $(this) );
        });    	
    }

    window.onload = function()
    {   
        template.init();
    }();

    $(window).resize(function()
	{
		template.init();
	});
});